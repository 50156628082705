var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"85vw","scrollable":true},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('icon-tooltip-button',_vm._g(_vm._b({attrs:{"icon-class":_vm.iconClass,"tooltip":_vm.tooltipText,"size":"large","icon":"mdi-format-list-bulleted"},on:{"click":_vm.handleDialogActivation}},'icon-tooltip-button',attrs,false),on))]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.carrier.name)+" - Appointments")]),_c('v-spacer'),_c('v-btn',{staticClass:"pa-3",attrs:{"icon":"","light":""},on:{"click":function($event){dialog.value = false}}},[_c('v-icon',[_vm._v("mdi-close-circle")])],1)],1),_c('v-card-text',[_c('v-row',{staticClass:"justify-center d-flex mt-0"},[_c('v-alert',{staticClass:"d-inline-flex",attrs:{"outlined":"","type":"info","dense":"","border":"left"}},[_vm._v(" Appointments for specific carriers can be exported on the "),_c('router-link',{attrs:{"to":{ name: 'insights.reporting' },"text":""},nativeOn:{"click":function($event){dialog.value = false}}},[_vm._v("reporting page")]),_vm._v(". ")],1)],1),_c('appointments-list',{attrs:{"build-headers-fn":_vm.buildTableHeaders,"format-appointments-fn":_vm.formatAppointments,"show-export-button":false,"show-print-button":false,"items-per-page":5,"manage-data-internally":true,"query-object":{
            s: {
              'user.company.id': { $eq: _vm.carrier.id },
              status: _vm.novaCore.AppointmentStatus.Completed
            },
            join: ['user.company']
          }}})],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v("Close ")])],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }