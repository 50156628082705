var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('h3',{staticClass:"mb-4"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-truck-outline")]),_vm._v(" "+_vm._s(_vm.companyName)+" ")],1),(_vm.appointment)?[_c('strong',[_vm._v("Scheduled for")]),_c('p',[_vm._v(" "+_vm._s(_vm.scheduledFor.date)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.scheduledFor.start)+" - "+_vm._s(_vm.scheduledFor.end)+" ")]),_c('strong',[_vm._v("Load Type")]),_c('p',[_vm._v(_vm._s(_vm.appointment.loadType.name)+" ("+_vm._s(_vm.appointment.loadType.direction)+")")]),(_vm.appointment.refNumber)?[_c('strong',[_vm._v("Reference Number")]),_c('p',[_vm._v(_vm._s(_vm.appointment.refNumber))])]:_vm._e(),[_c('strong',[_vm._v("Status")]),_c('p',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.appointment.status))])],_c('v-btn',{attrs:{"block":""},on:{"click":function($event){return _vm.$router.push({
          name: 'appointments',
          replace: true,
          query: {
            appointmentId: _vm.appointment.id
          }
        })}}},[_vm._v(" View Appointment ")])]:[_c('p',[_vm._v("No appointment scheduled")])],_c('v-divider',{staticClass:"my-6"}),[_c('h4',[_vm._v("Asset Events")]),_c('v-list',{attrs:{"tile":""}},_vm._l((_vm.assetVisitEvents),function(event){return _c('v-list-item',{key:event.id},[_c('div',{staticClass:"mr-4"},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-clock-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('strong',[_vm._v(_vm._s(event.eventType))])]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.formatHour(event.createDateTime)))])],1)],1)}),1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }