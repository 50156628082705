<template>
  <div class="full-height" v-if="appointmentWithWarehouse?.warehouse?.id">
    <section class="sidebar-content" :class="{ 'drivers-chat-tab': tab === 'drivers_chat' }">
      <div class="sidebar">
        <v-tabs v-model="tab" vertical :centered="false" hide-slider @change="handleTabChange">
          <v-tab tab-value="details" key="details">Status & Details</v-tab>
          <v-tab tab-value="claims_support" :disabled="claimSupportTriggers.length === 0">
            Claims Support
          </v-tab>
          <v-tab tab-value="gate_management">Gate Management</v-tab>
          <v-tab tab-value="drivers_chat">
            Drivers Chat
            <v-chip
              class="ml-1"
              x-small
              v-if="unreadMessagesCount"
              color="primary"
              data-testid="drivers-chat-tab-message-count">
              {{ unreadMessagesCount < 100 ? unreadMessagesCount : '99+' }}
            </v-chip>
          </v-tab>
          <v-tab tab-value="audit_log">Audit Log</v-tab>
        </v-tabs>
      </div>
      <div
        class="content"
        :class="{ 'drivers-chat-container': tab === 'drivers_chat' }"
        v-if="appointmentWithWarehouse?.id">
        <v-tabs-items v-model="tab">
          <v-tab-item value="details" :transition="false">
            <appointment-details-status
              v-if="tab === 'details'"
              v-bind="$props"
              :appointment="appointmentWithWarehouse" />
          </v-tab-item>
          <v-tab-item value="claims_support" :transition="false">
            <tab-content-centered>
              <template #content>
                <appointment-details-claims
                  v-if="tab === 'claims_support'"
                  v-bind="$props"
                  :appointment="appointmentWithWarehouse" />
              </template>
            </tab-content-centered>
          </v-tab-item>
          <v-tab-item value="gate_management" :transition="false">
            <tab-content-centered>
              <template #content>
                <appointment-details-gate-management
                  v-if="tab === 'gate_management'"
                  :triggers="gateManagementTriggers"
                  :appointment="appointmentWithWarehouse" />
              </template>
            </tab-content-centered>
          </v-tab-item>
          <v-tab-item value="drivers_chat" :transition="false">
            <drivers-chat-window
              v-if="tab === 'drivers_chat'"
              :warehouse="appointmentWithWarehouse.warehouse"
              :asset-visit="assetVisit"
              @update:phone="handlePhoneUpdate" />
          </v-tab-item>
          <v-tab-item value="audit_log" :transition="false" @click="trackApptDetailsMixpanelEvent">
            <tab-content-centered class="audit-log-tab-wrapper">
              <template #content>
                <details-content-box>
                  <appointment-details-audit-log v-if="tab === 'audit_log'" v-bind="$props" />
                </details-content-box>
              </template>
            </tab-content-centered>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </section>

    <custom-forms-flow-dialog
      :external-activator="true"
      :warehouse="appointment.dock.warehouse"
      :show-dialog="hasActiveTrigger"
      :object-id="appointment.id"
      :trigger="activeTrigger"
      @close="cleanUpCustomFormsData"
      @update="updateCustomFormsData"
      @create="
        value => createBulkCustomFormsData(value, appointment.dock.warehouse.id)
      "></custom-forms-flow-dialog>
  </div>
</template>

<script>
import { computed, nextTick, onBeforeMount, onBeforeUnmount, ref, watch } from 'vue';
import warehouseService from '@satellite/services/warehouse-service';
import {
  useAppointmentDetailsHelpers,
  useCustomForms,
  useEventHub,
  useNovaCore
} from '@/composables';
import { appointmentHelpersProps } from '@/composables/useAppointmentHelpers';
import { appointmentDetailsHelpersProps } from '@/composables/useAppointmentDetailsHelpers';
import { useRouter } from 'vue-router/composables';
import DetailsContentBox from '@/modules/appointments/components/details/DetailsContentBox.vue';

/**
 * @displayName Appointment Details
 */
export default {
  components: { DetailsContentBox },
  props: {
    ...appointmentHelpersProps,
    ...appointmentDetailsHelpersProps
  },
  setup(props) {
    const novaCore = useNovaCore();
    const eventHub = useEventHub();
    const {
      claimSupportTriggers,
      gateManagementTriggers,
      warehouseTriggers,
      trackApptDetailsMixpanelEvent
    } = useAppointmentDetailsHelpers(props.appointment, props.appointmentWarehouse);
    const {
      activeTrigger,
      customFormsData,
      pendingStatusChange,
      hasActiveTrigger,
      createBulkCustomFormsData,
      updateCustomFormsData,
      handleActionFieldTriggered,
      customFormsDataChange,
      cleanUpCustomFormsData,
      checkForTriggerOnStatus,
      getCustomFormsData
    } = useCustomForms(props.appointmentWarehouse.timezone, false, warehouseTriggers);
    const router = useRouter();
    const tab = ref('details');
    const warehouse = ref(null);
    const unreadMessagesCount = ref(0);
    const assetVisit = ref({});

    const appointmentWithWarehouse = computed(() => {
      return {
        ...props.appointment,
        warehouse: warehouse.value
      };
    });

    const handleTabChange = async tab => {
      const currentQuery = { ...router.currentRoute.query };
      currentQuery.appointmentId = props.appointment.id;
      currentQuery.tab = tab;
      await nextTick();
      router.push({ query: currentQuery });
    };

    watch(
      () => props.appointment?.id,
      async () => {
        warehouse.value = {
          ...props.appointmentWarehouse,
          ...(await warehouseService.getWarehouseById(props.appointment.dock.warehouseId, {}))
        };
        if (props.appointment.assetVisit?.id) {
          assetVisit.value = {
            ...props.appointment.assetVisit,
            ...{ warehouse: warehouse.value }
          };
          if (assetVisit.value?.messageThread) {
            unreadMessagesCount.value = assetVisit.value.messageThread.unreadMessagesCount;
          }
        }
      },
      { immediate: true }
    );

    const handleMessageThreadUpdate = messageThread => {
      if (messageThread.assetVisitId === assetVisit.value.id) {
        unreadMessagesCount.value = messageThread.unreadMessagesCount;
      }
    };

    const handleAssetVisitCreated = payload => {
      if (payload.appointmentId === props.appointment.id) {
        assetVisit.value = payload;
      }
    };

    const handlePhoneUpdate = phone => {
      assetVisit.value.phone = phone;
    };

    onBeforeMount(() => {
      eventHub.$on('update-MessageThread', handleMessageThreadUpdate);
      eventHub.$on('create-AssetVisit', handleAssetVisitCreated);
      eventHub.$on('action-field-submitted', handleActionFieldTriggered);
      if (router.currentRoute.query.tab) {
        tab.value = router.currentRoute.query.tab;
      }
      trackApptDetailsMixpanelEvent();
    });

    onBeforeUnmount(() => {
      eventHub.$off('update-MessageThread', handleMessageThreadUpdate);
      eventHub.$off('create-AssetVisit', handleAssetVisitCreated);
      eventHub.$off('action-field-submitted', handleActionFieldTriggered);
    });

    return {
      tab,
      appointmentWithWarehouse,
      novaCore,
      claimSupportTriggers,
      gateManagementTriggers,
      trackApptDetailsMixpanelEvent,
      handleTabChange,
      assetVisit,
      unreadMessagesCount,
      handlePhoneUpdate,
      activeTrigger,
      customFormsData,
      pendingStatusChange,
      hasActiveTrigger,
      createBulkCustomFormsData,
      updateCustomFormsData,
      handleActionFieldTriggered,
      customFormsDataChange,
      cleanUpCustomFormsData,
      checkForTriggerOnStatus,
      getCustomFormsData
    };
  }
};
</script>

<style scoped lang="scss">
.sidebar-content {
  display: flex;
  min-height: 100%;

  > .content {
    padding: 24px;
    flex: 1;
    background-color: $color-background-primary;

    &.drivers-chat-container {
      padding: 0;
      overflow: hidden;
    }
  }

  &.drivers-chat-tab {
    height: 100%;
  }
}

.sidebar {
  border-right: 1px solid $color-line-border;
}

.v-tabs {
  position: sticky;
  top: 0;
}

.v-tabs-items {
  background-color: $color-background-primary !important;
}

.v-tab {
  font-size: 12px;
  height: auto;
  padding: 16px 12px;
  border-bottom: 2px solid $color-line-border;
  font-weight: 600;

  &--active {
    color: $color-primary-100;
    border-bottom: 2px solid $color-primary-60;
  }
}

.v-window {
  height: 100%;
}

.v-window-item {
  height: 100%;
}

::v-deep .audit-log-tab-wrapper {
  &.content {
    background-color: $color-background-primary;
    padding: 24px;
    padding-top: 0;
  }
}

::v-deep {
  .v-v-input__append-inner {
    margin-top: 8px !important;
  }

  .v-select.v-text-field:not(.v-text-field--single-line) input {
    margin-top: 0;
  }
}
</style>

<style lang="scss">
.v-input--dense .v-input__icon {
  height: 24px !important;
}

.v-application .v-text-field__slot {
  margin-top: 0 !important;
}

.v-text-field {
  padding-top: 0;
  margin-top: 0;
}

.vue-tel-input-vuetify {
  .v-input__slot {
    min-height: 40px;
  }

  .v-select__selections {
    height: 40px;
  }

  label {
    line-height: 12px;
  }

  .country-code {
    width: 50px !important;

    .v-input__slot {
      width: 50px !important;
    }
    .v-select__selections {
      justify-content: center;
    }
    .vti__flag {
      margin-right: 0;
    }
  }
  > div:first-child {
    fieldset {
      border-radius: 5px 0 0 5px;
    }
    .v-input__slot {
      margin-bottom: 0;
    }
    .v-text-field__details {
      display: none;
    }
  }

  > div:not(:first-child) {
    fieldset {
      border-left: 0;
      border-radius: 0 5px 5px 0;
    }
  }
}
</style>
