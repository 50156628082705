import { render, staticRenderFns } from "./WarehousePowerBiInsights.vue?vue&type=template&id=0948e224&scoped=true"
import script from "./WarehousePowerBiInsights.vue?vue&type=script&lang=js"
export * from "./WarehousePowerBiInsights.vue?vue&type=script&lang=js"
import style0 from "./WarehousePowerBiInsights.vue?vue&type=style&index=0&id=0948e224&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0948e224",
  null
  
)

export default component.exports