var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-data",class:{ 'is-list': _vm.isList, 'is-compact text-dense': _vm.isCompact }},[_c('span',{staticClass:"d-flex",class:_vm.keyValueClasses},[(_vm.boldLabels)?_c('strong',[_vm._v("Phone Number")]):_c('div',[_vm._v("Phone Number:")]),_c('span',[_vm._v(_vm._s(_vm.assetVisit.phone ?? '----'))])]),_c('span',{staticClass:"d-flex",class:_vm.keyValueClasses},[(_vm.boldLabels)?_c('strong',[_vm._v("Carrier Company")]):_c('span',[_vm._v("Carrier Company:")]),_c('span',[_vm._v(_vm._s(_vm.company?.name ?? _vm.assetVisit.companyHint))])]),_vm._l((_vm.assetVisitFormData),function(data){return _c('span',{key:data.id,staticClass:"d-flex",class:_vm.keyValueClasses},[(_vm.boldLabels)?_c('strong',[_vm._v(_vm._s(data.label))]):_c('span',[_vm._v(_vm._s(data.label)+":")]),(_vm.isDocument(data))?_c('span',{domProps:{"innerHTML":_vm._s(
        _vm.getCustomFieldFormattedValue(data, {
          [_vm.CustomFieldType.Document]: { generateLink: true }
        })
      )}}):(_vm.isMultiDocument(data))?_c('span',{domProps:{"innerHTML":_vm._s(
        _vm.getCustomFieldFormattedValue(data, {
          [_vm.CustomFieldType.MultiDocument]: { generateLink: true }
        })
      )}}):_c('span',[_vm._v(_vm._s(_vm.getCustomFieldFormattedValue(data)))])])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }