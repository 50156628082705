var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form-base',{scopedSlots:_vm._u([{key:"form",fn:function(){return [_c('v-form',{ref:"form"},[_c('v-container',[_c('v-row',[_c('v-col',[_c('text-field',{attrs:{"required":true,"type":"text","rules":[..._vm.$validator.rules.required('Email'), ..._vm.$validator.rules.email],"label":"Email"},model:{value:(_vm.userInvite.email),callback:function ($$v) {_vm.$set(_vm.userInvite, "email", $$v)},expression:"userInvite.email"}})],1)],1),_c('v-row',[_c('v-col',[_c('text-field',{attrs:{"required":true,"type":"text","rules":_vm.$validator.rules.required('First Name'),"label":"First Name"},model:{value:(_vm.userInvite.firstName),callback:function ($$v) {_vm.$set(_vm.userInvite, "firstName", $$v)},expression:"userInvite.firstName"}})],1),_c('v-col',[_c('text-field',{attrs:{"required":true,"type":"text","rules":_vm.$validator.rules.required('Last Name'),"label":"Last Name"},model:{value:(_vm.userInvite.lastName),callback:function ($$v) {_vm.$set(_vm.userInvite, "lastName", $$v)},expression:"userInvite.lastName"}})],1)],1),_c('v-row',[_c('v-col',[_c('role-select',{attrs:{"data-testid":"role-select","excluded-roles":[
                _vm.novaCore.UserRole.BASE,
                _vm.novaCore.UserRole.CARRIER_ADMIN,
                _vm.novaCore.UserRole.CARRIER
              ],"parent-role":_vm.$me.role,"rules":_vm.$validator.rules.required('Role')},model:{value:(_vm.userInvite.role),callback:function ($$v) {_vm.$set(_vm.userInvite, "role", $$v)},expression:"userInvite.role"}})],1)],1),[_c('user-restriction-switch',{staticClass:"mt-1",attrs:{"read-only":_vm.novaCore.isUserWarehouseRestricted(_vm.$me),"role":_vm.userInvite?.role},model:{value:(_vm.shouldTargetUserBeRestrictedByLocation),callback:function ($$v) {_vm.shouldTargetUserBeRestrictedByLocation=$$v},expression:"shouldTargetUserBeRestrictedByLocation"}}),(
              _vm.shouldTargetUserBeRestrictedByLocation &&
              _vm.novaCore.canUserRoleHaveWarehouseAccessList(_vm.userInvite.role)
            )?_c('div',[_c('v-row',[_c('v-col',[_c('basic-warehouse-select',{attrs:{"restricted-warehouse-ids":_vm.currentUserWarehouseAccessList,"filter-locally":false,"multiple":""},model:{value:(_vm.selectedWarehouses),callback:function ($$v) {_vm.selectedWarehouses=$$v},expression:"selectedWarehouses"}})],1)],1),_c('v-row',[_c('v-col',[_c('p',{staticClass:"my-3"},[_vm._v("Max warehouses per user: "+_vm._s(_vm.novaCore.MAX_WAREHOUSE_PER_USER))])])],1)],1):_vm._e()]],2)],1)]},proxy:true},{key:"form-actions",fn:function(){return [_c('action-group',{attrs:{"confirm-icon":"plus","confirm-label":"Invite User"},on:{"cancel":function($event){return _vm.$emit('close')},"confirm":_vm.submit}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }