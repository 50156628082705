import { render, staticRenderFns } from "./DetailsHeader.vue?vue&type=template&id=baa6f5c0"
var script = {}


/* normalize component */
import normalizer from "!../../../../../../../apps/helios/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports