import { render, staticRenderFns } from "./CustomFieldDataBlock.vue?vue&type=template&id=f58c35b2&functional=true"
import script from "./CustomFieldDataBlock.vue?vue&type=script&lang=js"
export * from "./CustomFieldDataBlock.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports