var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.isFlex ? 'd-flex flex-row align-center mb-4 full-width' : ''},[_c('v-select',_vm._b({ref:_vm.selectRefKey,attrs:{"prepend-icon":!_vm.hideIcon ? 'mdi-warehouse' : '',"value":_vm.value,"items":_vm.warehouses,"item-disabled":_vm.noDocks,"multiple":"","loading":_vm.loading,"item-text":"name","item-value":"id","rules":_vm.selectRules,"return-object":"","clearable":"","disabled":_vm.disabled,"hide-details":"auto"},on:{"input":_vm.updateValue,"click:clear":_vm.clearSelection},scopedSlots:_vm._u([(_vm.required)?{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.placeholder)+" "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("*")])])]},proxy:true}:null,{key:"item",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.getWarehouseName(item))+" ")]}},{key:"no-data",fn:function(){return [(!_vm.warehouses.length)?_c('v-list-item',[_c('v-list-item-title',[_c('span',{staticClass:"d-block pt-2"},[_vm._v(" Looks like your org doesn't have a warehouse set up yet ... ")]),(_vm.showCreateWarehouseDialog)?_c('router-link',{staticClass:"full-width v-btn primary v-size--default mt-2",attrs:{"to":{
              name: 'warehouses',
              props: true,
              params: { shouldShowCreateWarehouseDialog: true }
            }},nativeOn:{"click":function($event){return _vm.handleCreateWarehouseClick.apply(null, arguments)}}},[_vm._v(" Let's Create a warehouse ")]):_vm._e()],1)],1):_c('v-list-item',[_c('v-list-item-title',[_c('span',{staticClass:"d-block pt-2"},[_vm._v("No warehouses found matching this criteria...")])])],1)]},proxy:true},{key:"append-item",fn:function(){return [(_vm.warehouseDoesNotHaveDocks)?[_c('v-list-item',[_c('v-list-item-title',[_c('span',{staticClass:"d-block pt-2"},[_vm._v(" Looks like your warehouse doesn't have a dock set up yet ... ")]),(_vm.warehouses.length && _vm.showCreateDockDialog)?_c('router-link',{staticClass:"full-width v-btn primary v-size--default mt-2",attrs:{"to":{
                name: 'warehouses.details.docks',
                props: true,
                params: { warehouseId: _vm.warehouses[0].id, shouldShowCreateDockForm: true }
              }},nativeOn:{"click":function($event){return _vm.handleCreateDockClick.apply(null, arguments)}}},[_vm._v(" Let's Create a Dock ")]):_vm._e()],1)],1)]:[(_vm.isAtSelectionLimit)?_c('select-limit-list-item',{attrs:{"limit":_vm.maximumSelections}}):_vm._e()]]},proxy:true},(_vm.warehouses.length && !_vm.disableSelectAll)?{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":_vm.toggleSelectAllItems}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.value.length > 0 ? 'error darken-4' : ''}},[_vm._v(_vm._s(_vm.selectAllIcon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.selectAllLabel))])],1)],1),_c('v-divider',{staticClass:"mt-2",attrs:{"slot":"prepend-item"},slot:"prepend-item"})]},proxy:true}:null,{key:"selection",fn:function({ item, index }){return [(index === 0)?_c('span',[_vm._v(_vm._s(item.name))]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption ml-2"},[_vm._v(" (+"+_vm._s(_vm.value.length - 1)+" others) ")]):_vm._e()]}}],null,true)},'v-select',[_vm.$props, _vm.$attrs],false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }