var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{ref:"appcontainer",staticClass:"lightBg is-relative overflow-clip",class:[
    _vm.$route.meta.pageClass,
    _vm.$route.meta.hideSidebar ? 'hide-sidebar' : '',
    { 'is-mobile fill-height': _vm.isMobile }
  ]},[_c('div',{class:{ 'is-mobile': _vm.isMobile },attrs:{"id":"app-notifications"}},[_c('app-notification',{staticClass:"mb-2",attrs:{"position":"bottom center","group":"bottom-notifications","max":5}}),_c('app-notification',{staticClass:"mt-2 mr-2",attrs:{"position":"top right","group":"top-notifications","max":5}})],1),(!_vm.$route.meta.hideSidebar && !_vm.shouldRenderMobile)?_c('navigation-drawer',{attrs:{"unread-msg-count":_vm.unreadMsgCount,"orgRequired":_vm.orgRequired},on:{"supportBtnClick":_vm._openChatWindow}}):_vm._e(),(_vm.shouldRenderMobile)?_c('header-mobile'):_vm._e(),_c('v-main',[(_vm.showDisabledOrgAlert)?_c('v-alert',{attrs:{"type":"error","prominent":"","text":""}},[_vm._v(" You are on View Only mode because your Org is inactive. Contact our team to request activation. ")]):_vm._e(),(_vm.$appLoaded)?_c('v-container',{class:{
        'viewport-height': _vm.orgRequired,
        'px-6 py-4': !_vm.$route.meta.fullScreen && !_vm.shouldRenderMobile,
        'pa-0 full-height': _vm.$route.meta.fullScreen || _vm.shouldRenderMobile
      },attrs:{"fluid":""}},[(!_vm.orgRequired && !_vm.$route.meta.customHeader && !_vm.isMobile)?_c('h1',{staticClass:"mb-6"},[_vm._v(" "+_vm._s(_vm.$pageTitle || _vm.$route.meta.title)+" ")]):_vm._e(),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('div',{class:{ 'page-wrapper': !_vm.shouldRenderMobile, 'full-height': _vm.shouldRenderMobile }},[(!_vm.shouldRenderMobile)?[(_vm.orgRequired)?_c('new-org-welcome'):_vm._e()]:_vm._e(),(_vm.isMobile && !_vm.$route.meta.allowMobile)?_c('not-ready-message'):_vm._e(),(!_vm.orgRequired && ((_vm.isMobile && _vm.$route.meta.allowMobile) || !_vm.isMobile))?_c('router-view',{class:{ 'is-mobile-content': _vm.shouldRenderMobile }}):_vm._e()],2)])],1):_vm._e()],1),_c('new-terms',{attrs:{"isConfirmationNeeded":_vm.isConfirmationNeeded}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }